import React, { useState } from 'react';
import { Box, InputAdornment, TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';

interface ChatInputProps {
  onSendMessage: (message: string) => void;
  onAISendMessage: (message: string) => void;
}

const ChatInput: React.FC<ChatInputProps> = ({ onSendMessage, onAISendMessage }) => {
  const [message, setMessage] = useState('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };

  const handleSendMessage = () => {
    const formattedText = message.replace(/\n/g, '  \n')
    onSendMessage(formattedText);
    setMessage('');
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault(); // Prevents default behavior of adding a newline
      handleSendMessage();
    }
  };

  // TODO: Styling Revamp [P1]
  return (
    <Box>
      <Box sx={{
        justifyContent: 'center',
        px: 1,
      }}>
        {/* <FilePicker 
          onFileChange={(fileContent, errorMsg) => {
            if (errorMsg) {
              onAISendMessage(errorMsg);
              return;
            }

            const chatGPTPrompt = `
            Read the following content, and let me know when you are done:
            ${fileContent}
            `.trim();
            console.log('chatGPTPrompt:', chatGPTPrompt);
            onSendMessage(chatGPTPrompt);
          }}
        /> */}
      </Box>
      <TextField
        fullWidth
        multiline
        maxRows={5}
        sx={{
          '& .MuiOutlinedInput-root': {
            'fieldset': {
              borderRadius: '15px'
            },
            '&:hover fieldset': {
              borderColor: '#49BAFD', // Color on hover
            },
            '&.Mui-focused fieldset': {
              borderColor: '#49BAFD', // Color on focus
            },
          },
          '& .MuiInputBase-inputMultiline': {
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          },
        }}
        placeholder="Send message"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                edge="end"
                onClick={handleSendMessage}
              >
                <SendIcon
                  sx={{
                    color: '#49BAFD'
                  }}
                />
              </IconButton>
            </InputAdornment>
          ),
        }}
        onChange={handleInputChange}
        onKeyDown={handleKeyPress}
        value={message}
      />
    </Box>
  );
};

export default ChatInput;