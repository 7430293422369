import { UserCredentials } from "../types/userCredentials";
import { BASE_URL } from "./constants"
import { TokenResponse } from "../types/refreshToken";

const getUser = async (session_token: string) => {
  const res = await fetch(`${BASE_URL}/user?user_session_token=${session_token}`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    }
  });

  if(!res.ok) {    
    switch(res.status) {
    case 404:
      return null;
    default:
      return null;
  }
}

  return res.json();
};

const getAgentName = async (session_token: string) => {
  const res = await fetch(`${BASE_URL}/user/ai_agent?user_session_token=${session_token}`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    }
  });

  if(!res.ok) {
    switch(res.status) {
      case 404:
        return null;
      default:
        return null;
    }
  }

  return res.json();
};

const login = async (token: string) => {
  const res = await fetch(`${BASE_URL}/signin/oauth`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ token })
  });

  return res.json();
}

const setCredentials = async (accountType: string, userCredentials: UserCredentials) => {
  const res = await fetch(`${BASE_URL}/user/credentials/${accountType}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(userCredentials)
  });

  return res.json();
}

const getCredentials = async (accountType: string, userId: string) => {
  const res = await fetch(`${BASE_URL}/user/credentials/${accountType}?user_id=${userId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return res.json();
}

const refreshGoogleToken = async (refreshToken: string): Promise<TokenResponse> => {
  return fetch(`${BASE_URL}/api/refresh-google-token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ refresh_token: refreshToken }),
  })
  .then(response => {
    if (!response.ok) {
      return response.json().then(errorData => {
        throw new Error(errorData.detail || `Error ${response.status}: Failed to refresh Google token`);
      });
    }
    return response.json();
  })
  .then(data => ({
    access_token: data.access_token,
    refresh_token: data.refresh_token,
  }));
}

export {
  getUser,
  getAgentName,
  login,
  setCredentials,
  getCredentials,
  refreshGoogleToken
};