import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RecordedAction } from '../../teach/recordedAction';
import { Box, Typography } from '@mui/material';
import RecordingStepDetailModal from '../../teach/RecordingStepDetailModal';
import LoadingComponent from '../../../components/loading';
import { useSnackbar } from '../../../context/SnackbarContext';
import { Skill, getSkill, getSkillAnalytics, getSkillAudio, updateSkill } from '../../../api/skills';
import { isChromeExtApp } from '../../../utils/chromeHelper';
import { addEventLogging } from '../../../api/eventLogging';
import { useUserContextController } from '../../../context/UserContext';
import ActionToolbox from '../../../components/guideMode/ActionToolbox';
import StepByStepCard from './StepByStepCard';
import DocumentationDetailToolbar from '../../../components/toolbar/DocumentationDetailToolbar';
import { WEB_BASE_URL } from '../../../api/constants';
import JSZip from 'jszip';
import { forgetDeleteDocumentation } from '../../../api/patiaAI';

interface DocumentationDetailsProps {
  isWebApp: boolean;
}

/**
 * Note: The react router will automatically redirect to home
 * if user tries to access page without document Id
 * 
 * Example:
 * Accessing '/documentation/details/' will redirect user to '/'
 * As '/documentation/details' is also an invalid route, it will also redirect user to '/'
 * This component will only be accessible if user access '/documentation/details/:documentId'
 */
const DocumentationDetails = (props: DocumentationDetailsProps) => {
  const [userContext] = useUserContextController();
  const { documentId } = useParams<{ documentId: string }>();
  const [skillData, setSkillData] = useState<Skill>({
    id: "",
    context: "",
    additional_context: "",
    create_ts: "",
    recorded_actions: [],
    audio_segments: {},
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [numOfView, setNumOfView] = useState<number>(-1);
  const [selectedRecordedAction, setSelectedRecordedAction] = useState<number | null>(null);
  const [interactiveGuidanceStep, setInteractiveGuidanceStep] = useState<number>(-1);
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  // Function to redirect to root on catch after API call
  const onErrorHandler = () => {
    setLoading(false);
    showSnackbar("Failed to fetch documentation, redirecting to home", 'error');
    setTimeout(() => {
      navigate('/home');
    }, 1500);
  }

  const fetchDocumentation = () => {
    if (!documentId) {
      onErrorHandler();
      return;
    }

    getSkill(documentId)
      .then((data) => {
        // TODO: check if audio_segments is empty etc. and set accordingly
        setSkillData({
          id: documentId,
          audio_segments: [],
          ...data,
        });

        getSkillAudio(documentId).then((blob) => {
          return JSZip.loadAsync(blob);
        }).then(async (zip) => {
          zip.forEach(async (relativePath, file) => {  
            const audioBlob = await file.async('blob');  
            const audioUrl = URL.createObjectURL(audioBlob);  

            const regex = /audio_segment_(\d+)\.mp3/;  
            const match = relativePath.match(regex);  
              
            let index = -1;
            if (match) {  
                index = parseInt(match[1]);  
            } else {
                throw new Error("Invalid audio segment file name");
            }  

            setSkillData((prev) => {
              const newAudioSegments = { ...prev.audio_segments };
              newAudioSegments[index] = audioUrl;
              return {
                ...prev,
                audio_segments: newAudioSegments
              };
            });
        }); 
      }).catch((error) => {
        // Handle error to support past skills without audio
      });

      getSkillAnalytics(documentId).then((analyticsData) => {
        setNumOfView(analyticsData.num_of_views);
      }).finally(() => {
        setLoading(false);
      });
    }).catch(onErrorHandler);
  };


  const documentIdRef = useRef(documentId);  
  const isWebAppRef = useRef(props.isWebApp);  
  const userIdRef = useRef(userContext?.user?.user_id);
  useEffect(() => {
    // TODO: Refactor and log upon mount not loading, for some reaseon this component gets mounted multiple times
    if (loading) return;
    const eventLog = {
      user_id: userIdRef.current || '',
      timestamp: new Date().toISOString(),
      event_type: "view_documentation",
      event_data: {
        platform: isWebAppRef.current ? "web" : "chrome_extension",
        skill_id: documentIdRef.current
      }
    }
    addEventLogging(eventLog);
  }, [loading]);

  useEffect(() => {
    // Set to teach mode for chrome extension
    if(props.isWebApp) return;    
    chrome.storage.session.set({
      dokaiExtMode: "teach"
    })
    return () => {
      chrome.storage.session.set({
        dokaiExtMode: "idle"
      })
    }// eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchDocumentation();
  // eslint-disable-next-line
  }, [documentId]);

  if (loading) {
    return <LoadingComponent />;
  }

  const setRecordedActions = (recordedActions: RecordedAction[]) => {
    setSkillData({
      ...skillData,
      recorded_actions: recordedActions
    });
  }

  return (
    <Box
      sx={{
        padding: '0 0 0 0',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        wordBreak: 'break-word',
        boxSizing: 'border-box'
      }}
    >
      <DocumentationDetailToolbar
        documentationId={skillData.id}
        onLaunchClick={() => window.open(`${WEB_BASE_URL}/documentation/details/${skillData.id}`, '_blank')}
        onDeleteClick={() => {
          setLoading(true);
          forgetDeleteDocumentation(userContext?.agentName || '', skillData.id).then(() => {
            showSnackbar('Skills deleted');
            navigate('/documentation/list');
          }).catch((error) => {
            console.error(error);
            showSnackbar('Failed to delete skills', 'error');
          }).finally(() => {
            setLoading(false);
          });
        }}
      />
      { skillData.context && (<Typography sx={{ fontWeight: '600', fontSize: '18px' }}>{skillData.context}</Typography>) }
      { numOfView >= 0 && (<Typography sx={{ fontSize: '14px', color: '#777777' }}>{numOfView} views</Typography>) }
      {/* TODO: open chrome extension upon clicking button, targeting the current URL */}
      {
        !isChromeExtApp() && (
          <ActionToolbox 
            editMode={editMode}
            recordedActions={skillData.recorded_actions}
            isManager={userContext?.user?.isManager || false}
            onEditClick={() => setEditMode(!editMode)}
            onSaveClick={() => {
              setLoading(true);
              updateSkill(
                skillData.id,
                skillData,
              ).then(() => {
                showSnackbar("Skill saved successfully", 'success');
                setEditMode(!editMode);
              }).catch(() => {
                showSnackbar("Failed to save skill", 'error');
              }).finally(() => {
                setLoading(false);
              });
            }}
          />
        )
      }
      { selectedRecordedAction !== null &&
        <RecordingStepDetailModal
          index={selectedRecordedAction}
          editMode={editMode}
          open={selectedRecordedAction !== null}
          onClose={(newRecordedAction: RecordedAction) => {
            const newRecordedActions = [...skillData.recorded_actions];
            newRecordedActions[selectedRecordedAction] = newRecordedAction;
            setRecordedActions(newRecordedActions);

            setSelectedRecordedAction(null)
          }}
          recordedAction={skillData.recorded_actions[selectedRecordedAction || 0]}
        />
      }
      <Box
        sx={{
          width: '100%',
          overflowY: "auto",
          maxHeight: "100%",
          alignContent: 'center',
          scrollbarWidth: "none",
          boxSizing: 'border-box'
        }}
      >
        {skillData.recorded_actions.map((recordedAction, idx) => (
          <StepByStepCard
            key={idx}
            idx={idx}
            recordedAction={recordedAction}
            audioSegment={skillData.audio_segments[idx]}
            interactiveGuidanceStep={interactiveGuidanceStep}
            editMode={editMode}
            onDeleteClick={() => {
              const newRecordedActions = skillData.recorded_actions.filter((_, i) => i !== idx);
              setRecordedActions(newRecordedActions);
            }}  
            onClick={() => {
              if (isChromeExtApp()) {
                chrome.runtime.sendMessage(
                  {
                    type: (recordedAction.action_type === 'navigation')
                      ? recordedAction.action_type
                      : 'highlight',
                    target: recordedAction.target,
                    querySelector: recordedAction.querySelector,
                    description: recordedAction.description
                  }
                );
                setInteractiveGuidanceStep(idx);
              } else {
                setSelectedRecordedAction(idx);
              }

              if(skillData.audio_segments[idx] === undefined) return;
              const audioHash = skillData.audio_segments[idx].split(`blob:${WEB_BASE_URL}/`).join('');
              const audioId = `audio-${audioHash}`;
              const audioPlayerSelector = document.getElementById(audioId) as HTMLAudioElement;
              audioPlayerSelector?.play();
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default DocumentationDetails;
