import { Button } from '@mui/material';
import { RecordedAction } from '../../pages/teach/recordedAction';
import { CHROME_EXTENSION_ID } from '../../api/constants';

interface StartInteractiveGuidanceButtonProps {
  recordedActions: RecordedAction[];
}

const StartInteractiveGuidanceButton = (props: StartInteractiveGuidanceButtonProps) => {
  const onClick = () => {
    chrome.runtime.sendMessage(
      CHROME_EXTENSION_ID,
      {type: 'startInteractiveGuidance', recordedActions: props.recordedActions});
  };

  return (
    <Button 
      sx={{
        borderRadius: 3,
        backgroundColor: "#31B1FD",
        fontSize: "10px",
        fontWeight: "600",
        my: 1
      }}
      variant="contained"
      color="primary" 
      onClick={onClick} 
    >Start guidance</Button>
  );
};

export default StartInteractiveGuidanceButton;