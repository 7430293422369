import { Box, CircularProgress, Typography } from '@mui/material';

const LoadingComponent = () => {
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress size={40} />
      <Typography sx={{
        margin: '10px',
      }}>
        Loading...
      </Typography>
    </Box>
  );
}

export default LoadingComponent;