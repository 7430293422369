import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import LoadingComponent from '../../../components/loading';
import { useSnackbar } from '../../../context/SnackbarContext';
import { SkillSummary, getSkillsSummaryCreateByUser } from '../../../api/skills';
import { useUserContextController } from '../../../context/UserContext';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

const DocumentationList: React.FC = () => {
  const [userContext] = useUserContextController();
  const { user } = userContext;
  // TODO: add interface for skills
  const [skillList, setSkillList] = useState<SkillSummary[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  // Function to redirect to root on catch after API call
  const onErrorHandler = () => {
    setLoading(false);
    showSnackbar("Failed to fetch skill list, redirecting to home", 'error');
    setTimeout(() => {
      navigate('/home');
    }, 1500);
  }

  const fetchSkills = async () => {
    setLoading(true);
    getSkillsSummaryCreateByUser(
      user?.user_id || "",
    ).then((response: SkillSummary[]) => {
      setSkillList(response);
      setLoading(false);
    }).catch(() => {
      onErrorHandler();
    });
  };

  useEffect(() => {
    if (!user) {
      navigate('/home');
    } else {
      fetchSkills();
    }
  // eslint-disable-next-line
  }, []);

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <Box
      sx={{
        padding: '20px 0 0',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'top',
        boxSizing: 'border-box'
      }}
    >
      <Typography sx={{ fontWeight: '600', fontSize: '18px' }}>Skill List</Typography>
      <Box
        sx={{
          width: '100%',
          padding: '20px',
          overflowY: "auto",
          maxHeight: "100%",
          alignContent: 'center',
          scrollbarWidth: "none",
          boxSizing: 'border-box'
        }}
      >
        {
          skillList?.length > 0 && (
            <List>
              {
                skillList.map((skillSummary: SkillSummary, idx: number) => (
                  <ListItem
                    sx={{
                      border: '1px solid #CCC',
                      borderRadius: '4px',
                      overflow: 'hidden',
                      maxWidth: '280px !important',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      margin: 'auto auto 10px auto',
                      cursor: 'pointer',
                      '&:hover': {
                        background: '#49BAFD',
                        color: 'white',
                        borderColor: '#49BAFD',
                        'svg': {
                          color: 'white',
                        }
                      }
                    }}
                    key={idx}
                    onClick={() => {
                      navigate(`/documentation/details/${skillSummary.id}`)
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        width: '30px',
                        minWidth: '30px',
                        color: '#49BAFD'
                      }}
                    >
                      <AutoFixHighIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography
                        sx={{
                          width: '100%',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          fontSize: '12px'
                        }}
                        title={skillSummary.context}
                      >
                        {skillSummary.context}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                ))
              }
            </List>
          )
        }
        {
          skillList.length === 0 && (
            <Typography
              sx={{
                textAlign: 'center'
              }}
            >
              No Skill found
            </Typography>
          )
        }
      </Box>
    </Box>
  );
};

export default DocumentationList;
