import { Box, Button, Typography } from '@mui/material';
import React from 'react';

interface ErrorFallbackProps {
  error: Error;
  clearError: () => void;
}

const ErrorFallback: React.FC<ErrorFallbackProps> = ({ error, clearError }) => {
  return (
    <Box
      sx={{
        boxSizing: 'border-box',
        padding: '20px',
        height: '100vh',
      }}
    >
      <Typography
        variant="h4"
        sx={{
          textAlign: 'center'
        }}
      >
        Oops.. Something went wrong
      </Typography>
      <Typography
        sx={{
          textAlign: 'center',
          margin: '100px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {error.message}
        <Button
          sx={{
            maxWidth: '100px',
            border: '1px solid #49BAFD',
            borderRadius: '5px',
            textTransform: 'capitalize',
            alignSelf: 'center',
            marginTop: '10px'
          }}
          onClick={clearError}
        >
          Try again
        </Button>
      </Typography>
    </Box>
  );
};

export default ErrorFallback;