import { Box } from "@mui/material";
import { useRef } from "react";

// import { getComponentId } from "../../api/llmExtractor";
import RecordedActionCard from "../learnMode/RecordedActionCard";

interface StepByStepListProps {
  steps: string[];
}

// const sendMessageToTab = (message: any, followUpMessage: any, actionMessage: string) => {
//   chrome.tabs.query({ active: true, currentWindow: true }, (tabs) => {  
//     const tabId = tabs[0].id;
//     if (tabId) {
//       chrome.tabs.sendMessage(tabId, message).then((allElementsOnTab) => {
//         // Fetch from backend 
//         getComponentId(allElementsOnTab, actionMessage).then((targetEl: any) => {
//           const tagName = targetEl.tagName.toLowerCase();
//           // elId can also be class name
//           followUpMessage.value = targetEl.id;
//           followUpMessage.tagClassName = targetEl.value;
//           followUpMessage.tagName = tagName;
//           chrome.tabs.sendMessage(tabId, followUpMessage);
//         });
//       });
//     }
//   });
// }

// const getActionCardMessage = (actionMessage: string) => {
//   if (actionMessage.includes('Navigate')) {
//     const urlIdx = actionMessage.indexOf('https://');
//     const url = actionMessage.slice(urlIdx);
//     chrome.tabs.update({url: url});
//   } else if (actionMessage.toLocaleLowerCase().includes('click')) {
//     const tagName = actionMessage.split(' ').pop()?.toLowerCase();
//     sendMessageToTab(
//       {type: 'getDom', value: tagName}, 
//       {type: 'highlightElement'}, 
//       actionMessage
//     );
//   } 
// }

const StepByStepList = (props: StepByStepListProps) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  return (
    <Box sx={{
      py: 2,
      borderBottom: '1px solid #e0e0e0',
    }}>
      <Box 
      ref={scrollRef}
      sx={{
        maxHeight: '200px',
        overflowY: 'auto',
        scrollbarWidth: 'thin',
        // px: 10,
      }}>
        {props.steps.map((step, idx) => (
          <RecordedActionCard
            key={idx}
            idx={idx}
            recordedAction={{
              action_type: '', // TODO: change to instruction action type
              description: step,
              dom: null,
              target: undefined, // TODO: change to instruction target
              timestamp: new Date().toISOString(),
              screenshotUrl: null,
            }}
            onClick={(messageType: string, targetElement: string, description: string) => {
              chrome.runtime.sendMessage(
                {
                  type: messageType,
                  target: targetElement,
                  description: description
                }
              );
            }}
          />  
        ))}
      </Box>
    </Box>
  );
}

export default StepByStepList;