import { BASE_URL } from "./constants"

const getPublicToken = async () => {
  const res = await fetch(`${BASE_URL}/env/get_google_public_token`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    }
  });

  if (!res.ok) {    
    switch(res.status) {
    case 404:
      return null;
    default:
      return null;
  }
}

  return res.json();
};

export {
  getPublicToken
};