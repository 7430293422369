import { useNavigate } from 'react-router-dom';
import LoginComponent from '../../components/login/LoginComponent';
import { 
  useUserContextController,
} from '../../context/UserContext';
import { Box, Button, Grid, Typography } from '@mui/material';
import WavingHandIcon from '@mui/icons-material/WavingHand';
import ChatIcon from '@mui/icons-material/Chat';
import SettingsIcon from '@mui/icons-material/Settings';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import MenuBookIcon from '@mui/icons-material/MenuBook';

interface HomePageProps {
  isWebApp: boolean;
}

const HomePage = (props: HomePageProps) => {
  const navigate = useNavigate();
  const [userContext] = useUserContextController();
  const { user } = userContext;

  if (!user) {
    return <LoginComponent />;
  }

  return (
    <Box
      sx={{
        padding: '10px',
        marginTop: '20px',
        maxWidth: '400px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        alignSelf: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography component="div" sx={{ marginBottom: '10px', fontWeight: '600', lineHeight: '24px', fontSize: '16px'}} >
        <Box display="flex" alignItems="center" justifyContent="center">
          Hi, Dokai here <WavingHandIcon sx={{ color: '#FBDC34', marginLeft: '5px', alignSelf: 'center' }} />
        </Box>
      </Typography>
      <Typography>
        Dokai here to help you. To teach me please select Teach Mode, if you need to talk to me select Guide Mode.
      </Typography>
      <Grid container spacing={2} sx={{ marginTop: '20px' }} justifyContent="center">
        <Grid item xs={6}>
          <Button
            sx={{
              color: 'black',
              backgroundColor: 'white',
              border: '1px solid #F1F3F4',
              padding: '10px 6px',
              lineHeight: '18px',
              fontSize: '12px',
              textTransform: 'capitalize',
              ':hover': {
                backgroundColor: 'white',
                color: '#49BAFD'
              }
            }}
            variant="contained"
            fullWidth
            startIcon={<ChatIcon />}
            onClick={() => navigate('/guide')}
          >
            Guide Mode
          </Button>
        </Grid>
        {!props.isWebApp && user.isManager && <Grid item xs={6}>
          <Button
            sx={{
              color: 'black',
              backgroundColor: 'white',
              border: '1px solid #F1F3F4',
              padding: '10px 6px',
              lineHeight: '18px',
              fontSize: '12px',
              textTransform: 'capitalize',
              ':hover': {
                backgroundColor: 'white',
                color: '#49BAFD'
              }
            }}
            variant="contained"
            fullWidth
            startIcon={<MenuBookIcon />}
            onClick={() => navigate('/teach')}
          >
            Teach Mode
          </Button>
        </Grid> }
        { user.isManager && <Grid item xs={6}>
          <Button
            sx={{
              color: 'black',
              backgroundColor: 'white',
              border: '1px solid #F1F3F4',
              padding: '10px 6px',
              lineHeight: '18px',
              fontSize: '12px',
              textTransform: 'capitalize',
              ':hover': {
                backgroundColor: 'white',
                color: '#49BAFD'
              }
            }}
            variant="contained"
            fullWidth
            startIcon={<AutoFixHighIcon />}
            onClick={() => navigate('/documentation/list')}
          >
            Skills
          </Button>
        </Grid> }
        <Grid item xs={6}>
          <Button
            sx={{
              color: 'black',
              backgroundColor: 'white',
              border: '1px solid #F1F3F4',
              padding: '10px 6px',
              lineHeight: '18px',
              fontSize: '12px',
              textTransform: 'capitalize',
              ':hover': {
                backgroundColor: 'white',
                color: '#49BAFD'
              }
            }}
            variant="contained"
            fullWidth
            startIcon={<SettingsIcon />}
            onClick={() => navigate('/profile')}
          >
            Settings
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HomePage;