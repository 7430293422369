import React from 'react';
import { Typography } from '@mui/material';
import { useCookies } from 'react-cookie';
import { useSnackbar } from '../../context/SnackbarContext';

const LogoutButton: React.FC = () => {
  const [,, removeCookie] = useCookies(['user_session_token']);
  const { showSnackbar } = useSnackbar();
  const handleLogout = () => {
    removeCookie('user_session_token', {
      path: '/'
    });
    showSnackbar("User logged out, redirecting to login page", "success");
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  return (
    <Typography
      onClick={handleLogout}
      sx={{
        width: '100%',
        padding: '10px 20px',
        fontSize: '14px'
      }}
    >
      Sign Out
    </Typography>
  );
};

export default LogoutButton;