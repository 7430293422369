import React from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { IconButton } from '@mui/material';
import { EnumReviewType } from '../../types/message';
import { setLastUserResponse, useMessageContextController } from '../../context/MessageContext';

interface ChatOutputToolboxProps {
  onRefreshClick: () => void;
  onCopyClick: () => void;
  onLikeClick: (arg: string) => void;
  onDislikeClick: (arg: string) => void;
}

const ChatOutputToolbox: React.FC<ChatOutputToolboxProps> = ({ 
  onRefreshClick, 
  onCopyClick,
  onLikeClick,
  onDislikeClick,
}) => {
  const [messageStates, dispatch] = useMessageContextController();
  const { lastUserResponse } = messageStates;

  return (
    <div style={{
      display: 'flex',
      marginTop: 4,
    }}
    >
      <IconButton sx={{
        mr: 1,
        p: 0,
      }}
      onClick={onRefreshClick}
      >
        <RefreshIcon style={{ fontSize: '12px' }}/>
      </IconButton>
      <IconButton
        sx={{
          mr: 1,
          p: 0,
        }}
        onClick={onCopyClick}
      >
        <ContentCopyIcon style={{ fontSize: '12px' }}/>
      </IconButton>
      <IconButton
        sx={{
          mr: 1,
          p: 0,
          pointerEvents: lastUserResponse ? 'none' : 'auto',
          // TODO: Add color to Themes
          color: lastUserResponse === EnumReviewType.LIKE
            ? '#49BAFD' : '#AAAAAA'
        }}
        onClick={() => {
          // Checker if lastUserResponse exists, stored in context (To 
          // handle issue where state is reset when draft message is added)
          if (!lastUserResponse) {
            setLastUserResponse(dispatch, EnumReviewType.LIKE);
            onLikeClick(EnumReviewType.LIKE);
          }
        }}
      >
        <ThumbUpIcon style={{ fontSize: '12px' }}/>
      </IconButton>
      <IconButton
        sx={{
          mr: 1,
          p: 0,
          pointerEvents: lastUserResponse ? 'none' : 'auto',
          // TODO: Add color to Themes
          color: lastUserResponse === EnumReviewType.DISLIKE
            ? '#49BAFD' : '#AAAAAA'
        }}
        onClick={() => {
          // Checker if lastUserResponse exists, stored in context (To 
          // handle issue where state is reset when draft message is added)
          if (!lastUserResponse) {
            setLastUserResponse(dispatch, EnumReviewType.DISLIKE);
            onDislikeClick(EnumReviewType.DISLIKE);
          }
        }}
      >
        <ThumbDownIcon style={{ fontSize: '12px' }}/>
      </IconButton>
    </div>
  );
};

export default ChatOutputToolbox;