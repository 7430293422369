import React from 'react';
import Markdown from 'react-markdown';
import './ChatMarkdown.css';
import CodeMarkdown from './CodeMarkdown';

interface ChatMarkdownProps { 
  children: React.ReactNode; 
}

const ChatMarkdown = ({ children }: ChatMarkdownProps) => {
  const childrenStr = children?.toString() ?? '';

  return (
    <Markdown
      className="chatmarkdown-container"
      components={{
        code({ children, node }) {
          const numOfLines = node?.position? 
            ((node.position.end.line - node.position.start.line) + 1) : 0;
          return (
            <CodeMarkdown numOfLines={numOfLines}>{children}</CodeMarkdown>
          )
        },
        a({ children, href }) {
          return (<a href={href} rel="noreferrer" target="_blank">{children}</a>);
        },
        img({ src, alt }) {
          return (<img src={src} alt={alt} style={{
            width: '100px',
          }}/>);
        },
      }}
    >
      {childrenStr}
    </Markdown>
  );
};

export default ChatMarkdown;
