import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import ChatInput from '../../components/Input/ChatInput';
import ChatThreadView from '../../components/chat/ThreadView';
import { sendAIMessage, sendMessage } from '../../utils/AIMessage';
import { Message } from '../../types/message';
import { setLastUserResponse, useMessageContextController } from '../../context/MessageContext'; 
import { 
  useUserContextController,
} from '../../context/UserContext';
import { DEFAULT_AGENT_NAME } from '../../api/constants';
import LoadingComponent from '../../components/loading';

interface GuideModePageProps {
  isWebApp: boolean;
}

const GuideMode = (props: GuideModePageProps) => {
  const [messageStates, dispatch] = useMessageContextController();
  const [userContext] = useUserContextController();
  const { messages } = messageStates;
  const { isUserLoading, user, agentName } = userContext;
  const setMessages = (value: Message[] | Function) => {
    dispatch({ type: 'SET_MESSAGE', value });
  }

  useEffect(() => {
    if(props.isWebApp) return;

    chrome.storage.session.set({
      dokaiExtMode: "guide"
    })

    return () => {
      chrome.storage.session.set({
        dokaiExtMode: "idle"
      })
    }// eslint-disable-next-line
  }, []);

  if(isUserLoading) {
    return <LoadingComponent />;
  }

  if (!user) {
    return <Navigate to="/home" replace />;
  }

  const ChatInterface = () => {
    return (<>
      <ChatThreadView messages={messages} agentName={agentName || DEFAULT_AGENT_NAME} />
      <Box>
        <ChatInput 
          onSendMessage={(message: string) => {
            setLastUserResponse(dispatch, undefined);
            sendMessage(agentName || DEFAULT_AGENT_NAME, message, setMessages, {})
          }}
          onAISendMessage={(message: string) => {
            setLastUserResponse(dispatch, undefined);
            sendAIMessage(message, agentName || DEFAULT_AGENT_NAME, setMessages);
          }}
        />
      </Box>
    </>)
  }

  return (
    <ChatInterface />
  );
};

export default GuideMode;