import { Avatar, Card, CardActionArea, CardContent, Tooltip, Typography } from "@mui/material";
import { RecordedAction } from "../../pages/teach/recordedAction";

interface RecordedActionCardProps {
  idx: number;
  recordedAction: RecordedAction;
  onClick: (messageType: string, targetElement: string, description: string) => void;
}

const RecordedActionCard = (props: RecordedActionCardProps) => {
  const { idx, recordedAction } = props;
  return (
    <Tooltip title={recordedAction.description} placement="top">
      <Card
        variant="outlined" 
        sx={{
          my: 1,
          backgroundColor: 'white',
          borderColor: '#31B1FD',
          borderWidth: '1px',
          borderRadius: '10px',
        }}
        onClick={() => {
          if (recordedAction.target) {
            props.onClick(recordedAction.action_type, recordedAction.target, recordedAction.description)
          }
        }}
      >
        <CardActionArea>
          <CardContent>
            <Typography variant="body2" color="textSecondary" sx={{ 
              display: 'flex', 
              alignItems: 'center',
            }}>
              <Avatar sx={{ width: 24, height: 24, fontSize: 14, backgroundColor: "#31B1FD", marginRight: 2 }}>{idx}</Avatar>
              {recordedAction.description.length > 40 ? recordedAction.description.slice(0, 40) + '...' : recordedAction.description}
            </Typography>
            { recordedAction.screenshotUrl && (
              <img
                style={{
                  maxWidth: '100%',
                  marginTop: '10px'
                }}
                alt={`step-${idx + 1}`}
                src={recordedAction.screenshotUrl}
              />
            )}
          </CardContent>
        </CardActionArea>
      </Card>
    </Tooltip>
  );
}

export default RecordedActionCard;