import { Box, Button } from "@mui/material";
import StartInteractiveGuidanceButton from "../button/StartInteractiveGuidanceButton";
import { RecordedAction } from "../../pages/teach/recordedAction";

interface ActionToolboxProps {
  recordedActions: RecordedAction[];
  isManager: boolean;
  editMode: boolean;
  onEditClick: () => void;
  onSaveClick: () => void;
}

const ActionToolbox = (props: ActionToolboxProps) => {
  return (
    <Box 
      sx={{
        display: 'flex',
        gap: 2,
      }}
    >
      { props.isManager && (
        <Button
          sx={{
            borderRadius: 3,
            backgroundColor: "#31B1FD",
            fontSize: "10px",
            fontWeight: "600",
            my: 1
          }}
          variant="contained"
          color="primary" 
          onClick={props.editMode ? props.onSaveClick : props.onEditClick}
        >
          {props.editMode ? "Save skill" : "Edit skill"}
        </Button>
      )}
      {!props.editMode && <StartInteractiveGuidanceButton 
        recordedActions={props.recordedActions}
      />}
    </Box>
  );
}

export default ActionToolbox;