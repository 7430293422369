import { Button } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import GoogleDrivePicker from "google-drive-picker";
import { refreshGoogleToken, setCredentials } from '../../api/userProfile';
import { setGoogleCredentials, useUserContextController } from '../../context/UserContext';
import Bugsnag from '@bugsnag/js';
import { useSnackbar } from '../../context/SnackbarContext';
import { GOOGLE_ACCOUNT_TYPE } from '../../types/userCredentials';

interface GDrivePickerProps {
  token: string | null | undefined;
  refreshToken: string | null | undefined;
  onFilesSelect: (docs: any[], access_token: string | undefined) => void;
}

const GDrivePicker = (props: GDrivePickerProps) => {
  const apiKey = process.env.REACT_APP_GOOGLE_DRIVE_API_KEY;
  const clientId = process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID;
  const { showSnackbar } = useSnackbar();
  const [userContext, userDispatch] = useUserContextController();
  const { user } = userContext;
  const accountType = GOOGLE_ACCOUNT_TYPE.WEB_APP;

  const [openPicker] = GoogleDrivePicker();

  const handlePickerOpen = () => {
    refreshGoogleToken(props.refreshToken || '')
      .then(tokens => {
        const userCredentials = {
          user_id: String(user?.user_id),
          credentials: {
            google: {
              access_token: tokens.access_token,
              refresh_token: tokens.refresh_token || props.refreshToken
            }
          }
        }
        openPicker({
          clientId: clientId,
          developerKey: apiKey,
          viewId: "DOCS",
          token: tokens.access_token ? tokens.access_token : props.token || undefined,
          showUploadView: true,
          showUploadFolders: true,
          supportDrives: true,
          multiselect: true,
          callbackFunction: (data: any) => {
            if (data.docs && data.docs.length > 0) {
              props.onFilesSelect(data.docs, tokens.access_token);
            }
          }
        });
        setCredentials(accountType, userCredentials).then(() => {
          setGoogleCredentials(userDispatch, {
            access_token: tokens.access_token || '',
            refresh_token: tokens.refresh_token || props.refreshToken || ''
          });
        }).catch(() => {
          Bugsnag.notify("Failed to set user credentials after google refresh token");
        });
      })
      .catch(error => {
        Bugsnag.notify(error.message);
        showSnackbar("Error in authorizing user", "error");
      });
  };

  return (
    <Button
      sx={{
        border: '1px solid #CCCCCC',
        justifyContent: 'space-between',
        marginBottom: '20px',
        borderRadius: '8px',
        width: '100%',
        padding: '10px 16px',
        textAlign: 'left',
        textTransform: 'capitalize',
        color: '#49BAFD'
      }}
      onClick={() => {
        handlePickerOpen();
      }}
    >
      Pick File from Google Drive
      <ChevronRightIcon
        sx={{
          color: '#CCCCCC'
        }}
      />
    </Button>
  );
}

export default GDrivePicker;