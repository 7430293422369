import { Dispatch, createContext, useContext, useMemo, useReducer } from 'react';

export type User = {
  user_id: string;
  emails: string[];
  company: string;
  isManager: boolean;
}

export type Provider = {
  provider_type: string;
  provider_subject: string;
  profile_picture_url: string;
  locale: string;
  oauth_user_registration_id: string;  
}

export type GoogleCredentials = {
  access_token: string;
  refresh_token: string;
}

export type SlackCredentials = {
  access_token: string;
}

export type NotionCredentials = {
  access_token: string;
}

type UserState = {
  isUserLoading: boolean;
  user: User | null;
  agentName: string | null;
  googleCredentials: GoogleCredentials | null;
  slackCredentials: SlackCredentials | null;
  notionCredentials: NotionCredentials | null;
}

// Define the initial state
const initialState = {
  isUserLoading: true,
  user: null,
  agentName: null,
  googleCredentials: null,
  slackCredentials: null,
  notionCredentials: null
};

type Action = 
  | { type: 'SET_IS_USER_LOADING'; payload: boolean }
  | { type: 'SET_USER'; payload: any }
  | { type: 'SET_AGENT_NAME'; payload: string }
  | { type: 'SET_GOOGLE_CREDENTIALS'; payload: GoogleCredentials }
  | { type: 'SET_NOTION_CREDENTIALS'; payload: NotionCredentials }
  | { type: 'SET_SLACK_CREDENTIALS'; payload: SlackCredentials };


// Define the reducer function
function reducer(state: any, action: any) {
  switch (action.type) {
    case 'SET_IS_USER_LOADING':
      return {
        ...state,
        isUserLoading: action.payload,
      };
    case 'SET_USER':
      return {
        ...state,
        user: action.payload,
      };
    case 'SET_AGENT_NAME':
      return {
        ...state,
        agentName: action.payload,
      };
    case 'SET_GOOGLE_CREDENTIALS':
      return {
        ...state,
        googleCredentials: action.payload,
      };
    case 'SET_NOTION_CREDENTIALS':
      return {
        ...state,
        notionCredentials: action.payload,
      };
    case 'SET_SLACK_CREDENTIALS':
      return {
        ...state,
        slackCredentials: action.payload,
      };
    default:
      return state;
  }
}

// Create the context object
const UserContext = createContext<
[UserState, Dispatch<Action>] | undefined
>(undefined);

// Create the provider component
function UserContextProvider({ children }: { children: React.ReactNode }) {
  const reducerValue = useReducer(reducer, initialState);
  const value = useMemo(() => reducerValue, [reducerValue]);

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
}

function useUserContextController() {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error(
      'userUserContext should be used inside the UserContextProvider.'
    );
  }
  return context;
}

// Define the actions
const setIsUserLoading = (dispatch: Dispatch<Action>, value: boolean) => {
  dispatch({ type: 'SET_IS_USER_LOADING', payload: value });
}
const setUser = (dispatch: Dispatch<Action>, value: any) => {
  dispatch({ type: 'SET_USER', payload: value });
};
const setAgentName = (dispatch: Dispatch<Action>, value: string) => {
  dispatch({ type: 'SET_AGENT_NAME', payload: value });
}
const setGoogleCredentials = (dispatch: Dispatch<Action>, value: GoogleCredentials) => {
  dispatch({ type: 'SET_GOOGLE_CREDENTIALS', payload: value });
};
const setNotionCredentials = (dispatch: Dispatch<Action>, value: NotionCredentials) => {
  dispatch({ type: 'SET_NOTION_CREDENTIALS', payload: value });
};
const setSlackCredentials = (dispatch: Dispatch<Action>, value: SlackCredentials) => {
  dispatch({ type: 'SET_SLACK_CREDENTIALS', payload: value });
};

export {
  UserContextProvider,
  useUserContextController,
  setIsUserLoading,
  setUser,
  setAgentName,
  setGoogleCredentials,
  setNotionCredentials,
  setSlackCredentials
};
