import { useState } from 'react';
import {
  Button,
  Modal,
  Box,
  Typography,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import { getIngestedDocuments } from '../../api/patiaAI';
import { useUserContextController } from '../../context/UserContext';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { DEFAULT_AGENT_NAME } from '../../api/constants';

const ViewIngestedDocuments = () => {
  const [userContext] = useUserContextController();
  const { agentName } = userContext;
  // TODO: Error handling
  const [open, setOpen] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    fetchChannels();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fetchChannels = async () => {
    setLoading(true);
    setError(false);
    getIngestedDocuments(
      agentName || DEFAULT_AGENT_NAME
    ).then((response) => {
      setDocuments(response);
      setLoading(false);
    }).catch((error) => {
      console.error("error", error);
      setError(true);
    });
  };

  // TODO: Improvement of API response to show document Names and document types
  const DocumentList = () => (
    <>
      {
        documents?.length > 0 && (
          <List>
            {
              documents.map((document: any, idx: number) => (
                <ListItem
                  sx={{
                    cursor: 'pointer',
                    '&:hover .MuiTypography-root': {
                      fontWeight: 'bold !important'
                    }
                  }}
                  key={`${document._id}-${idx}`}
                  onClick={() => {
                    window.open(document._url, "_blank");
                  }}
                >
                  <ListItemIcon
                    sx={{
                      width: '30px',
                      minWidth: '30px',
                      color: '#49BAFD'
                    }}
                  >
                    <FileCopyIcon />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography
                      sx={{
                        width: '100%',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        fontSize: '12px'
                      }}
                      title={document._url}
                    >
                      {document._name}
                    </Typography>
                  </ListItemText>
                </ListItem>
              ))
            }
          </List>
        )
      }
    </>
  )

  return (
    <Box>
      <Button
        sx={{
          justifyContent: 'space-between',
          marginBottom: '20px',
          borderRadius: '8px',
          padding: '8px',
          textAlign: 'left',
          textTransform: 'capitalize',
          color: '#49BAFD'
        }}
        onClick={handleOpen}
      >
        View Ingested Documents
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box sx={{ ...style, width: 400 }}>
          <Typography variant="h6" component="h2">
            Ingested Documents
          </Typography>
          {loading && <CircularProgress />}
          {error && <Typography color="error">Error loading documents</Typography>}
          {!loading && !error && (
            <DocumentList />
          )}
        </Box>
      </Modal>
    </Box>
  );
};

const style = {
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  maxHeight: 400,
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
      display: 'none', // For Chrome, Safari, and Edge
  },
  overflow: 'auto',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default ViewIngestedDocuments;
