import { CircularProgress } from '@mui/material';

import ChatMarkdown from '../ChatMarkdown/ChatMarkdown';
import { Message, SourceNode } from '../../types/message';
import Sources from './SourcesComponent';
import ChatOutputToolbox from './ChatOutputToolbox';
import StepByStepList from '../guideMode/StepByStepList';
import { 
  useMessageContextController,
  removeLastMessage, 
  setLastUserResponse 
} from '../../context/MessageContext';

import { useUserContextController } from '../../context/UserContext';
import { addEventLogging } from '../../api/eventLogging';
import { generateAIResponse } from '../../utils/AIMessage';
import { setMessages } from '../../context/MessageContext';

import { DEFAULT_AGENT_NAME } from '../../api/constants';

interface ChatOuputProps {
  isLoading: boolean;
  text: string;
  stepByStep: string[];
  sourceNodes: SourceNode[];
  showToolbox: boolean;
}

const ChatOutput = (props: ChatOuputProps) => {
  const [userContext] = useUserContextController();
  const [, dispatch] = useMessageContextController();
  const { agentName } = userContext;
  const updateMessages = (value: Message[] | Function) => {
    setMessages(dispatch, value);
  }


  const sendEventLog = (event_type: string) => {
    // TODO: add error handling and handling of null values
    const eventLog = {
      user_id: userContext?.user?.user_id || '',
      timestamp: new Date().toISOString(),
      event_type: event_type,
      event_data: {}
    }
    addEventLogging(eventLog);
  }

  if(props.isLoading) {
    return <CircularProgress size={14}/>
  }

  return (
    <>
      <ChatMarkdown>{props.text}</ChatMarkdown>
      {props.showToolbox && props.stepByStep.length > 0 &&
        <StepByStepList 
          steps={props.stepByStep}
        />
      }
      {
        props.sourceNodes.length > 0 && (
          <Sources nodes={props.sourceNodes} />
        )
      }
      {props.showToolbox && <ChatOutputToolbox
        onRefreshClick={() => {
          setLastUserResponse(dispatch, undefined);
          removeLastMessage(dispatch);
          // TODO: Add additional data
          generateAIResponse(agentName || DEFAULT_AGENT_NAME, updateMessages)
        }} 
        onCopyClick={() => {
          navigator.clipboard.writeText(props.text);
        }}
        onLikeClick={(event_type) => {
          sendEventLog(event_type);
        }}
        onDislikeClick={(event_type) => {
          sendEventLog(event_type);
        }}
      />}
  </>
  )
}

export default ChatOutput;