import { Avatar, Box, Card, CardActionArea, CardActions, CardContent, IconButton, Typography } from "@mui/material";
import { RecordedAction } from "../../teach/recordedAction";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AudioPlayer from "../../../components/audio/player";

interface StepByStepCardProps {
  idx: number;
  audioSegment: any; // TODO: create audio segment interface
  recordedAction: RecordedAction;
  interactiveGuidanceStep: number;
  onClick: () => void;
  onDeleteClick: () => void;
  editMode: boolean;
}

const StepByStepCard = (props: StepByStepCardProps) => {
  return (
    <Card
      key={props.idx}
      variant="outlined" sx={{
        margin: 'auto',
        maxWidth: '600px',
        my: 1,
        backgroundColor: props.interactiveGuidanceStep >= props.idx ? '#CCCCCC' : 'white',
        borderColor: props.interactiveGuidanceStep >= props.idx ? '#CCCCCC' : '#49BAFD',
        borderWidth: '1px',
        borderRadius: '10px',
      }}
    >
      {props.editMode && (
        <div style={{ position: 'relative' }}>  
          <CardActions 
            style={{ 
              position: 'absolute', 
              top: 0, 
              right: 0, 
              zIndex: 1 
            }} 
            onClick={props.onDeleteClick}
          >  
            <IconButton aria-label="delete">  
              <DeleteForeverIcon />  
            </IconButton>  
          </CardActions>  
        </div>
      )}

      <CardActionArea onClick={props.onClick}>
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
            }}
          >
            <Avatar sx={{
              width: 24,
              height: 24,
              fontSize: 14,
              backgroundColor: props.interactiveGuidanceStep >= props.idx ? "white" : "#49BAFD",
              color: props.interactiveGuidanceStep >= props.idx ? "black" : "white",
              marginRight: 2
            }}>{props.idx}</Avatar>

              <Typography variant="body2" color="textSecondary" sx={{ 
                display: 'flex',
                alignItems: 'center',
                wordBreak: 'break-word',
                textAlign: 'left',
              }}>
                {props.recordedAction.description.length > 40 ? props.recordedAction.description.slice(0, 40) + '...' : props.recordedAction.description}
              </Typography>
            </Box>
            { props.recordedAction.screenshotUrl && (
              <img
                style={{
                  maxWidth: '100%',
                  marginTop: '10px'
                }}
                alt={`step-${props.idx + 1}`}
                src={props.recordedAction.screenshotUrl}
              />
            )}
        </CardContent>
      </CardActionArea>
      { props.audioSegment && (
        <CardActions disableSpacing>
          <AudioPlayer audioURL={props.audioSegment} />        
        </CardActions>
      )}
    </Card>
  );
}

export default StepByStepCard;