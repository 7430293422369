import React, { ComponentType, FC, ReactNode } from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { isChromeExtApp } from './chromeHelper';
import { BASE_URL } from '../api/constants';

// Define the type for the props expected by the fallback component
interface ErrorFallbackProps {
  error: Error;
  info: { componentStack: string };
  clearError: () => void; // Add the clearError function
}

// Define the type for the props expected by BugsnagErrorBoundary
interface BugsnagErrorBoundaryProps {
  children: ReactNode;
  FallbackComponent: FC<ErrorFallbackProps>;
}

const fetchBugsnagToken = async (): Promise<string | null> => {
  try {
    const response = await fetch(`${BASE_URL}/env/get_bugsnag_api_key`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {    
      switch(response.status) {
        case 404:
          return null;
        default:
          return null;
      }
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching Bugsnag token:', error);
    return null;
  }
}

const initializeBugsnag = (apiKey: string): ComponentType<BugsnagErrorBoundaryProps> | undefined => {
  Bugsnag.start({
    apiKey,
    plugins: [new BugsnagPluginReact(React)],
  });
  return Bugsnag.getPlugin('react')?.createErrorBoundary() as ComponentType<BugsnagErrorBoundaryProps> | undefined;
}

// Initialize as undefined initially
let BugsnagErrorBoundary: ComponentType<BugsnagErrorBoundaryProps> | undefined = undefined;

if (isChromeExtApp()) {
  // The app is running as a Chrome extension
  fetchBugsnagToken().then((token) => {
    if (token) {
      BugsnagErrorBoundary = initializeBugsnag(token);
    } else {
      BugsnagErrorBoundary = undefined;
    }
  }).catch(() => {
    BugsnagErrorBoundary = undefined;
  })
} else {
  // The app is not running as a Chrome extension, use environment variable
  BugsnagErrorBoundary = initializeBugsnag(process.env.REACT_APP_BUGSNAG_API_KEY as string);
}

export { Bugsnag, BugsnagErrorBoundary };
