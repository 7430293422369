import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import { MessageContextProvider } from './context/MessageContext';
import { UserContextProvider } from './context/UserContext';

import { BugsnagErrorBoundary } from './utils/bugsnag';
import ErrorFallback from './components/ErrorComponent';
import { SnackbarProvider } from './context/SnackbarContext';

const root = document.createElement("div")
root.className = "container"
document.body.appendChild(root)
const rootDiv = ReactDOM.createRoot(root);
if (BugsnagErrorBoundary) {
  rootDiv.render(
    <React.StrictMode>
      <BugsnagErrorBoundary FallbackComponent={ErrorFallback}>
        <SnackbarProvider>
          <UserContextProvider>
            <MessageContextProvider>
              <App />
            </MessageContextProvider>
          </UserContextProvider>
        </SnackbarProvider>
      </BugsnagErrorBoundary>
    </React.StrictMode>
  );
}