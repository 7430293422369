import SyntaxHighlighter from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Box, Button } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export interface CodeMarkdownProps {
  numOfLines: number;
  children: React.ReactNode;
}

const CodeMarkdown = ({ numOfLines, children }: CodeMarkdownProps) => {
  const codeStr = children?.toString() ?? ''

  if (numOfLines === 1) {
    return <strong>{codeStr}</strong>
  }

  return (
    <Box sx={{
      width: '100%',
      overflowX: 'auto',
      backgroundColor: 'grey',
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
    }}>
      <Button
        size="small" 
        startIcon={<ContentCopyIcon style={{ fontSize: 16 }}/>}
        onClick={() => {
          navigator.clipboard.writeText(codeStr);
        }}
        sx={{
          px: 2,
          py: "6px",
          color: 'white',
          fontSize: 8,
        }}
      >
        Copy
      </Button>
      <SyntaxHighlighter
        wrapLines
        style={dark}
        customStyle={{
          marginTop: 0,
          borderWidth: 0,
          borderRadius: 0,
        }}
      >
        {codeStr}
      </SyntaxHighlighter>
    </Box>
  )
}

export default CodeMarkdown;