import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { RecordedAction } from './recordedAction';
import { Button, TextField } from '@mui/material';
import { useState } from 'react';

interface EndRecordingModalProps {
  index: number;
  open: boolean;
  recordedAction: RecordedAction;
  onClose: (recordedAction: RecordedAction) => void;
  handleDelete?: (index: number) => void;
  editMode: boolean;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "80%",
  maxHeight: '600px',
  overflowY: 'auto',
  scrollbarWidth: 'thin',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
  justifyContent: "center",
  wrap: "wrap",
};

const RecordingStepDetailModal = (props: EndRecordingModalProps) => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [stepContext, setStepContext] = useState(props.recordedAction.description);

  const deleteStep = () => {
    if (props.handleDelete) {
      props.handleDelete(props.index);
    }
  }

  // TODO: add max height and scrollable for long text
  return (
      <Modal
        open={props.open}
        onClose={() => {
          props.onClose({
            ...props.recordedAction,
            description: stepContext,
          })
        }}
      > 
        <Box sx={style}>
          <Typography variant='h6'>Step description:</Typography>
          {props.editMode ? <TextField
            fullWidth
            defaultValue={props.recordedAction.description}
            id="outlined-multiline-flexible"
            multiline
            maxRows={4}
            sx={{
              marginBottom: 2
            }}
            onChange={(e) => setStepContext(e.target.value)}
          /> : <Typography>{props.recordedAction.description}</Typography>}

          { props.recordedAction.screenshotUrl && (
            <Box>
              <Typography
                sx={{
                  marginTop: '10px'
                }}
                variant='h6'
              >
                Screenshot:
              </Typography>
              <img
                alt={`step-${props.index}-screenshot`}
                style={{
                  maxWidth: '100%',
                }}
                src={props.recordedAction.screenshotUrl}
              />
            </Box>
          )}
          {/* Only show delete button if handleDelete is provided as props */}
          { props.handleDelete && (<>
            {
              confirmDelete
                ? <>
                  <Button
                    sx={{
                      textTransform: 'capitalize'
                    }}
                    color="inherit"
                    onClick={() => setConfirmDelete(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    sx={{
                      textTransform: 'capitalize'
                    }}
                    color="error"
                    onClick={deleteStep}
                  >
                    Confirm
                  </Button>
                </>
                : <Button
                    sx={{
                      textTransform: 'capitalize'
                    }}
                    color="error"
                    onClick={() => setConfirmDelete(true)}
                  >
                    Delete Step
                  </Button>
            }
          </>)}
        </Box>
      </Modal>
  );
}

export default RecordingStepDetailModal;