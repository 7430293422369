import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { NOTION_AUTH_BASE_URL } from "../../api/constants";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { getAccessToken } from "../../api/notion";
import { GOOGLE_ACCOUNT_TYPE } from "../../types/userCredentials";
import { setNotionCredentials, useUserContextController } from "../../context/UserContext";
import { setCredentials } from "../../api/userProfile";
import { useSnackbar } from "../../context/SnackbarContext";
import { useState } from "react";
import NotionLogo from '../../assets/NotionLogo.svg';
import Bugsnag from "@bugsnag/js";
import { URL_PATHNAMES } from "../layout";

interface NotionAuthComponentProps {
  notionAuthToken: string | undefined;
  userId: string;
}

// TODO: Move to types (Rename to NotionEnumMessageType)
enum EnumMessageType {
  SUCCESS = 'SUCCESS',
  FAILED_CREDENTIALS = 'FAILED_CREDENTIALS',
  FAILED_AUTH = 'FAILED_AUTH',
  PREEMPTIVE = 'PREEMPTIVE'
};
const changeScopeSnackbarMessages = {
  [EnumMessageType.SUCCESS]: 'Access change successful',
  [EnumMessageType.FAILED_CREDENTIALS]: 'Failed to change scope of access',
  [EnumMessageType.FAILED_AUTH]: 'Failed to change scope of access',
  [EnumMessageType.PREEMPTIVE]: 'Failed to change scope of access',
}
const linkAccountSnackbarMessages = {
  [EnumMessageType.SUCCESS]: 'Login to Notion successful',
  [EnumMessageType.FAILED_CREDENTIALS]: 'Failed to link Notion account',
  [EnumMessageType.FAILED_AUTH]: 'Failed to link Notion account',
  [EnumMessageType.PREEMPTIVE]: 'Failed to link Notion account',
}

// TODO: Implement token revocation to remove from credentials
const NotionAuthComponent = (props: NotionAuthComponentProps) => {
  const [loading, setLoading] = useState(false);
  const [, userDispatch] = useUserContextController();
  const { showSnackbar } = useSnackbar();
  const messageSettings = props.notionAuthToken
    ? changeScopeSnackbarMessages
    : linkAccountSnackbarMessages;

  const onSignIn = (token: string) => {
    if (token) {
      const accountType = GOOGLE_ACCOUNT_TYPE.WEB_APP;
      // NOTE: Notion endpoint does not return refresh_token
      const userCredentials = {
        user_id: String(props.userId),
        credentials: {
          notion: {
            access_token: token,
          }
        }
      }
      setCredentials(accountType, userCredentials).then(() => {
        showSnackbar(messageSettings.SUCCESS, 'success');
        setNotionCredentials(userDispatch, {
          access_token: token
        });
        setLoading(false);
      }).catch(() => {
        Bugsnag.notify("Credentials Error: " + messageSettings.FAILED_CREDENTIALS);
        showSnackbar(messageSettings.FAILED_CREDENTIALS, 'error');
        setLoading(false);
      })
    } else {
      Bugsnag.notify("Auth Error: " + messageSettings.FAILED_AUTH);
      showSnackbar(messageSettings.FAILED_AUTH, 'error');
      setLoading(false);
    }
  }

  const onClick = () => {
    setLoading(true);
    // TODO: Clean up, move token and URL to env or BE
    const loginRedirectUrl = `${window.location.origin}${URL_PATHNAMES.HOME}`;

    const publicToken = process.env.REACT_APP_NOTION_OAUTH_CLIENT_ID;
    const url = `${NOTION_AUTH_BASE_URL}?owner=user&client_id=${publicToken}&redirect_uri=${loginRedirectUrl}&response_type=code`;

    const extractCode = (url: string) => {
      const urlParams = new URLSearchParams(new URL(url).search);  
      return urlParams.get('code') || "";  
    }

    const popup = window.open(url, "popup", "popup=true");
    const checkPopup = setInterval(async () => {
      let popupClosedPreemptively = true;
      try {
        if (popup && popup.window.location.href.includes(window.location.origin)) {
          const code = extractCode(popup.location.href);
          clearInterval(checkPopup);
          const responseObject = await getAccessToken(code, loginRedirectUrl);
          popupClosedPreemptively = false;
          onSignIn(responseObject.access_token);
          popup.close();
        }
      } catch (error) {
        // Entering this error is expected while the url is in the unexpected loc
      }
      if (!popup || !popup.closed) return;
      clearInterval(checkPopup);
      // Set loading false and show snackbar but does not throw error on bugsnag
      if (popupClosedPreemptively) {
        setLoading(false);
        showSnackbar(messageSettings.PREEMPTIVE, "error");
      }
    }, 1000);
  };

  return (
    <Box
      sx={{
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        border: '1px solid #CCCCCC',
        justifyContent: 'space-between',
        marginBottom: '20px',
        borderRadius: '8px',
        width: '100%',
        padding: '20px 16px',
        textAlign: 'left',
        textTransform: 'capitalize',
        color: 'black',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'start'
        }}
      >
        <img
          alt="notion-logo"
          src={NotionLogo}
          style={{
            height: '20px',
            width: '20px',
            marginRight: '10px',
            fontSize: '20px',
            alignSelf: 'center',
          }}
        />
        <Typography
          sx={{
            alignSelf: 'center',
            fontWeight: '600',
          }}
        >
          Notion
        </Typography>
        {/* Show check mark if auth token exists */}
        {
          props.notionAuthToken &&
          <CheckCircleIcon
            sx={{
              marginLeft: '4px',
              width: '16px',
              height: '16px',
              color: '#28a745',
              alignSelf: 'center',
            }}
          />
        }
      </Box>
      <Button
        sx={{
          boxSizing: 'border-box',
          padding: '0',
          textAlign: 'left',
          textTransform: 'capitalize',
          fontWeight: '600',
          fontSize: '12px',
          color: '#49BAFD',
          ':hover': {
            background: 'none'
          }
        }}
        onClick={onClick}
        disabled={loading}
        endIcon={loading ? <CircularProgress size={12} /> : null}
      >
        { props.notionAuthToken? 'Change Access Scope' : 'Link Account' }
      </Button>
    </Box>
  );
}

export default NotionAuthComponent;