import Box from '@mui/material/Box';
import NavigationButton from '../NavigationButton/NavigationButton';
import HomeIcon from '@mui/icons-material/Home';
import ChatIcon from '@mui/icons-material/Chat';
import SettingsIcon from '@mui/icons-material/Settings';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { WEB_BASE_URL } from '../../api/constants';

interface AppNavigationsProps {
  isWebApp: boolean;
  isManager: boolean;
}

const AppNavigations = (props: AppNavigationsProps) => {
  return (
    <Box
      sx={{
        width: '100%',
        padding: '5px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        boxSizing: 'border-box',
      }}
    >
      <NavigationButton icon={HomeIcon} text="Home" to="/home" />
      <NavigationButton icon={ChatIcon} text="Guide" to="/guide" />
      {!props.isWebApp && props.isManager && <NavigationButton icon={MenuBookIcon} text="Teach" to="/teach" />}
      {props.isManager && <NavigationButton icon={AutoFixHighIcon} text="Skills" to="/documentation/list" />}
      <NavigationButton
        icon={SettingsIcon} 
        text="Settings" 
        to={props.isWebApp ? "/profile" : `${WEB_BASE_URL}/profile`}
        target={props.isWebApp ? undefined : "_blank"}
      />
    </Box>
  )
}

export default AppNavigations;
