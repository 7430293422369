import React, { useEffect, useRef } from 'react';
import Grid from '@mui/material/Grid'; // Grid version 1
import { ChatMessage } from './ChatMessage';
import { Message } from '../../types/message';

interface ChatThreadViewProps {
  messages: Message[];
  agentName: string;
}

const ChatThreadView: React.FC<ChatThreadViewProps> = ({ messages, agentName }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Scroll to the bottom of the container
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [messages]); // Trigger effect whenever messages change

  const showToolbox = (message: Message, idx: number) => {
    // Check if last message is draft or not. If is draft, treat length - 2 as last message index
    const lastMessage = messages[messages.length - 1].isDraft
      ? idx === messages.length - 2
      : idx === messages.length - 1;
    return message.sender === agentName && !message.isLoading && idx !== 0 && messages.length !== 1 && lastMessage;
  }

  return (
    <Grid container 
      ref={containerRef}
      spacing={2}
      sx={{
        width: '100%',
        ml: '0',
        mb: '10px',
        overflowY: 'scroll',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            display: 'none', // For Chrome, Safari, and Edge
        },
        justifyContent: 'flex-end',
        boxSizing: 'border-box'
      }}
    >
      {messages.map((message, idx) => (
        <Grid key={idx} item xs={12}>
          <ChatMessage 
            key={message.id} 
            text={message.text}
            sender={message.sender}
            isLoading={message.isLoading}
            showToolbox={showToolbox(message, idx)}
            sourceNodes={message.sourceNodes}
            instructions={message.instructions}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ChatThreadView;