import React, { useState } from 'react';
import {
  Button,
  Modal,
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  FormGroup,
  Tooltip,
} from '@mui/material';
import { searchNotionPages } from '../../api/notion';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ingestDocument } from '../../api/patiaAI';
import Bugsnag from '@bugsnag/js';
import { useSnackbar } from '../../context/SnackbarContext';

interface NotionPagePickerProps {
  notionAccessToken: string | undefined;
  agentName: string;
}

const NotionPagePickerComponent = ( props: NotionPagePickerProps ) => {
  // TODO: Error handling (P0 Task) for empty response, error in fetch etc.
  const [open, setOpen] = useState(false);
  const [pages, setPages] = useState([]);
  const [selectedPages, setSelectedPages] = useState<string[]>([]);
  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState<string|null>(null);
  const [onSubmitProgress, setOnSubmitProgress] = useState(false);
  const { showSnackbar } = useSnackbar();
  const notionAccessToken = props.notionAccessToken

  if (notionAccessToken) {
    const handleOpen = () => {
      setError(null);
      setOnSubmitProgress(false);
      setOpen(true);
      fetchPages();
    };

    const handleClose = () => {
      setError(null);
      setOnSubmitProgress(false);
      setOpen(false);
      resetForm();
    };

    const resetForm = () => {
      setSelectedPages([]);
    };

    const fetchPages = async () => {
      setLoadingData(true);
      setError(null);
      // For now keep query empty
      searchNotionPages("", notionAccessToken).then((response) => {
        const pagesWithTitle = response.results.filter((page: any) => {
          return Object.values(page.properties).some((property: any) => property.type === 'title');
        });
        setPages(pagesWithTitle);
        setLoadingData(false);
      }).catch((error) => {
        Bugsnag.notify(error);
        setError("Error loading Notion page list");
      });
    };

    const handlePageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const pageId = event.target.value;
      setSelectedPages((prev) =>
        prev.includes(pageId)
          ? prev.filter((id) => id !== pageId)
          : [...prev, pageId]
      );
    };

    const handleApply = () => {
      setOnSubmitProgress(true);
      const selectedPagesMetadata = pages.filter((page) => selectedPages.includes(page["id"]));

      ingestDocument(
        props.agentName, 
        selectedPages, 
        "notion_pages", 
        notionAccessToken,
        selectedPagesMetadata,
      ).then((response) => {
        showSnackbar(response.message, 'success');
        setOnSubmitProgress(false);
        handleClose();
      }).catch((error) => {
        // TODO: show snackbar and close modal upon failure instead of showing error on component to align with google doc ingestion
        setOnSubmitProgress(false);
        Bugsnag.notify(error);
        setError("Error ingesting Notion pages");
      })
    };

    return (
      <Box>
        <Button
          sx={{
            border: '1px solid #CCCCCC',
            justifyContent: 'space-between',
            marginBottom: '20px',
            borderRadius: '8px',
            width: '100%',
            padding: '10px 16px',
            textAlign: 'left',
            textTransform: 'capitalize',
            color: '#49BAFD'
          }}
          onClick={handleOpen}
        >
          Pick a Page from Notion
          <ChevronRightIcon
            sx={{
              color: '#CCCCCC'
            }}
          />
        </Button>
        <Modal open={open} onClose={handleClose}>
          <Box sx={{ ...style, width: 400 }}>
            <Typography variant="h6" component="h2">
              Select Pages
            </Typography>
            {loadingData && <CircularProgress />}
            {onSubmitProgress && <Typography>Ingesting documents... This might take a while</Typography>}
            {error && <Typography color="error">{error}</Typography>}
            {!loadingData && !onSubmitProgress && !error && (
              <FormGroup
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexWrap: 'nowrap',
                  maxHeight: '300px',
                  scrollbarWidth: 'none',
                  overflow: 'auto',
                  '&::-webkit-scrollbar': {
                      display: 'none', // For Chrome, Safari, and Edge
                  },
                }}
              >
                {pages.map((page: any) => {
                  const titleProperty: any = Object.values(page.properties)
                    .find((property: any) => property.type === 'title');
                  return (
                    <FormControlLabel
                      key={page.id}
                      control={
                        <Checkbox
                          checked={selectedPages.includes(page.id)}
                          onChange={handlePageChange}
                          value={page.id}
                        />
                      }
                      label={titleProperty.title[0]?.text.content
                        || titleProperty[0]?.text.content
                        || 'No Title'}
                    />
                  );
                })}
              </FormGroup>
            )}
            {
              (pages.length > 0 && selectedPages.length > 0 && !error) && (
                <Button
                  endIcon={onSubmitProgress ? <CircularProgress size={20} /> : null}
                  variant="contained"
                  sx={{
                    backgroundColor: '#49BAFD'
                  }}
                  disabled={onSubmitProgress}
                  onClick={handleApply}
                >
                  Apply
                </Button>
              )
            }
          </Box>
        </Modal>
      </Box>
    );
  } else {
    return (
      <Box>
        <Tooltip 
          title="Link account to start ingesting notion pages"
        >
          <Button
            sx={{
              border: '1px solid #CCCCCC',
              justifyContent: 'space-between',
              marginBottom: '20px',
              borderRadius: '8px',
              width: '100%',
              padding: '10px 16px',
              textAlign: 'left',
              textTransform: 'capitalize',
              color: '#CCCCCC',
              ':hover': {
                backgroundColor: 'inherit',
                cursor: 'not-allowed'
              }
            }}
          >
            Pick a page from Notion
            <ChevronRightIcon
              sx={{
                color: '#CCCCCC'
              }}
            />
          </Button>
        </Tooltip>
      </Box>
    );
  }
};

const style = {
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  maxHeight: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default NotionPagePickerComponent;
