import { EventLog, EventLoggingRange } from "../types/eventLog";
import { BASE_URL } from "./constants"

// Endpoint to add event logging
const addEventLogging = async (eventLog: EventLog) => {
  try {
    const response = await fetch(`${BASE_URL}/event_logging`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(eventLog),
    });

    if (!response.ok) {
      throw new Error(`Error logging event: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error logging event:', error);
    throw error;
  }
};

// TODO (STILL UNUSED): Endpoint to get event logs
const getEventLog = async (eventRange: EventLoggingRange): Promise<EventLog[]> => {
  try {
    const params = new URLSearchParams({
      user_id: eventRange.user_id.toString(),
      start_time: eventRange.start_time,
      end_time: eventRange.end_time,
    });

    const response = await fetch(`${BASE_URL}/event_logging?${params}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`Error fetching event logs: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching event logs:', error);
    throw error;
  }
};

export {
  addEventLogging,
  getEventLog
};