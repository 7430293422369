import './styles/App.css';
import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import HomePage from './pages/home';
import GuideMode from './pages/guide';
import { 
  useUserContextController, 
  setAgentName,
  setIsUserLoading,
  setUser,
  setGoogleCredentials,
  setSlackCredentials,
  setNotionCredentials,
} from './context/UserContext';
import { getAgentName, getCredentials, getUser } from './api/userProfile';
import { useCookies } from 'react-cookie';
import Layout from './components/layout';
import ProfilePage from './pages/profile';
import { GOOGLE_ACCOUNT_TYPE } from './types/userCredentials';
import LoadingComponent from './components/loading';
import { setInitialMessageAgent, useMessageContextController } from './context/MessageContext';
// import AgentDashboard from './pages/profile/agent-dashboard';
import DocumentationPage from './pages/documentation/details/';
import DocumentationListPage from './pages/documentation/list';

function App() {
  // Code to test bugsnag. When throwing errors locally, will show error window (this won't show on production)
  // and will redirect app to ErrorComponent.
  // useEffect(() => {
  //   throw new Error("Please try again later");
  // }, [])
  const [cookies, , removeCookie] = useCookies(['user_session_token']);
  const [userContext, userDispatch] = useUserContextController();
  const { user, isUserLoading } = userContext;
  const [, dispatch] = useMessageContextController();
  const { user_session_token } = cookies;

  useEffect(() => {
    if (user_session_token) {
      setIsUserLoading(userDispatch, true);
      getUser(user_session_token).then((user) => {
        if (user) {
          setUser(userDispatch, user);
          const accountType = GOOGLE_ACCOUNT_TYPE.WEB_APP;
          getCredentials(accountType, user.user_id).then((response) => {
            if (response?.credentials?.google) {
              setGoogleCredentials(userDispatch, {
                access_token: response.credentials.google.access_token,
                refresh_token: response.credentials.google.refresh_token,
              });
            }
            if (response?.credentials?.slack) {
              setSlackCredentials(userDispatch, {
                access_token: response.credentials.slack.access_token
              });
            }
            if (response?.credentials?.notion) {
              setNotionCredentials(userDispatch, {
                access_token: response.credentials.notion.access_token
              });
            }
          }).finally(() => {
            setIsUserLoading(userDispatch, false);
          });
        } else {
          setIsUserLoading(userDispatch, false);
          removeCookie('user_session_token', { path: '/' });
          window.location.reload();
        }
      })
      .catch(() => {
        setIsUserLoading(userDispatch, false);
        removeCookie('user_session_token', { path: '/' });
        window.location.reload();
      });

      getAgentName(user_session_token).then((agentName: string) => {
        setAgentName(userDispatch, agentName);
        // To set sender of first message to agentName after fetching.
        setInitialMessageAgent(dispatch, agentName);
      });
    } else {
      setIsUserLoading(userDispatch, false);
    }
  // To remove warning for exhaustive deps
  // eslint-disable-next-line
  }, [user_session_token]);

  if (isUserLoading) {
    return <LoadingComponent />
  }

  // Todo: set global margin in constant file
  return (
    <Box sx={{
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      height: '100vh',
      overflow: 'hidden',
      backgroundColor: '#F1F3F4',
      borderRadius: '15px',
    }}>
      {/* TODO: Add Theme Provider */}
      <Router>
        <Routes>
          <Route element={<Layout isWebApp />}>
            {/* TODO: Handle private routes instead of redirection in component */}
            <Route path="/home" element={<HomePage isWebApp />} />
            <Route path="/guide" element={<GuideMode isWebApp/>} />
            <Route path="/profile" element={<ProfilePage />} />
            {/* <Route path="/profile/agent-dashboard" element={<AgentDashboard />} /> */}
            <Route path="/documentation/details/:documentId" element={<DocumentationPage isWebApp/>} />
            { user?.isManager && <Route path="/documentation/list" element={<DocumentationListPage />} /> }
            {/* Catch-all route to redirect to root */}
            <Route path="*" element={<Navigate to="/guide" replace />} />
          </Route>
        </Routes>
      </Router>
    </Box>
  );
}

export default App;
